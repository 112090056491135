import React, { useState } from 'react'
import { Card } from 'reactstrap'
import ComeingsoonShapeImg1 from '../../assets/images/coming-shape.webp';
import ComeingsoonShapeImg2 from '../../assets/images/coming-shape2.webp';
import ComeingsoonShapeImg3 from '../../assets/images/coming-shape3.webp';
import { ComingSoonSvg } from "../Common/CommonSvg"
import { Link } from 'react-router-dom';
import Header from '../../pages/common/Header';
import HelmetComponent from '../Seo/HelmetComponent';

export default function ComingSoon() {
    const [isSidebar, setIsSidebar] = useState(false);
    const handleSidebarOpen = () => {
        setIsSidebar(!isSidebar)
    }
    return (
        <>
            <HelmetComponent />
            <div className={"front-wrapper header-fix"}>
                <Header isSidebar={isSidebar} handleSidebarOpen={handleSidebarOpen} />
                <Card className='coming-soon-page'>
                    <div className='inner-content'>
                        <h2>Coming Soon...</h2>
                        <Link to={"/"} className="btn btn-primary" type="submit" title='Back to home'>
                            Back to home
                        </Link>

                    </div>
                    <div className='img-box'>
                        <img src={ComeingsoonShapeImg1} alt='coming_soon' />
                        <img src={ComeingsoonShapeImg2} alt='coming_soon' />
                        <img src={ComeingsoonShapeImg3} alt='coming_soon' />
                    </div>
                    <div className="cloud-img">
                        <ComingSoonSvg />
                    </div>
                </Card>
            </div>
        </>
    )
}
