import "./App.css";
import "sweetalert2/src/sweetalert2.scss";
import { Route, Routes } from "react-router-dom";
import LayoutFront from "./pages/common/Layout";
import HomePage from "./pages/LandingPage/HomePage";
import AboutUs from "./pages/LandingPage/AboutUs";
import Features from "./pages/LandingPage/Features";
import NotFound from "./components/404/NotFound";
import TermsConditions from "./pages/LandingPage/Termsconditions";
import PrivacyPolicy from "./pages/LandingPage/Privacypolicy";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import TearmsApp from "./pages/LandingPage/TearmsApp";
function App() {
  return (
    <>
      <Routes>
        <Route element={<LayoutFront />}>
          <Route index element={<HomePage />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/features" element={<Features />} />{" "}
          <Route path="/termsconditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
        <Route element={<TearmsApp />}>
          <Route path="/app/termcondition" element={<TermsConditions brodcum={true} />} />
          <Route path="/app/privacy-policy" element={<PrivacyPolicy brodcum={true} />} />
        </Route >
        <Route path="/getapp" element={<ComingSoon />} />
        <Route path="/getplan" element={<ComingSoon />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
