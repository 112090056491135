import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";
import Aboutus from "../../assets/front-images/about-us-mockup.webp";
import BoatShape from "../../assets/front-images/boat-shape.webp";
import FeatureImg from "../../assets/front-images/features-img.webp";
import FeatureImg2 from "../../assets/front-images/features-img2.webp";
import FeatureImg3 from "../../assets/front-images/features-img3.webp";
import FeatureImg4 from "../../assets/front-images/features-img4.webp";
import TrainImg from "../../assets/front-images/train.webp";
import SubscribeMockup from "../../assets/front-images/subscribe-mockup.webp";
import Roundshape from "../../assets/front-images/round-dots-white.webp";
import Dropshape from "../../assets/front-images/drop-shape.webp";
import Dropshape2 from "../../assets/front-images/drop-shape.webp";
import PlansImg from "../../assets/front-images/plan-img.webp";
import PlansImg2 from "../../assets/front-images/plan-img2.webp";
import PlansImg3 from "../../assets/front-images/plan-img3.webp";
import CalltoactionMockup from "../../assets/front-images/call-to-action-mockup.webp";
import Playstore from "../../assets/front-images/google-play.webp";
import Applestore from "../../assets/front-images/apple-store.webp";
import PlaneShape from "../../assets/front-images/plan-shape.webp";
import BannerSlide from "../../assets/front-images/slider-mockup.webp";
import BannerSlide2 from "../../assets/front-images/slider-mockup2.webp";
import BannerSlide3 from "../../assets/front-images/slider-mockup3.webp";
import { Field, Form, Formik } from "formik";
import {
    AirballoonSvg,
    BirdSvg,
    GirlDrawSvg,
    GirlPlaySvg,
    PapperPlaneSvg,
    QuoteSvg,
    RounddotedSvg,
    SunSvg,
    Ticksvg,
} from "../../components/Common/CommonFrontSvg";
import { connect } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { __t } from "../../i18n/translator";
import { mainSlide, testimonialSlide } from "./commonFunction";
import { sendemailRequest } from "../../redux/landingpage/action";
import HelmetComponent from "../../components/Seo/HelmetComponent";
import { useScroll } from "../../helper/scrollhelper";
import { APP_STORE, PLAY_STORE } from "../../redux/constant/authConstants";
import { getPlansRequest } from "../../redux/plans/action";

function HomePage(props) {
    const { planList } = props
    const validator = useRef(new SimpleReactValidator());
    const [subscribe, setSubscribe] = useState(false);
    const { tableRef, isApple, isDesktop, handleScroll } = useScroll();
    const handleSubmit = (values) => {
        if (validator.current.allValid()) {
            props.SendEmailRequest(values);
        } else {
            validator.current.showMessages();
        }
    };

    useEffect(() => {
        if (props.subStatus) {
            setSubscribe(true);
        }
    }, [props.subStatus]);
    useEffect(() => {
        const timer = setTimeout(() => {
            setSubscribe(false);
        }, 10000);
        return () => clearTimeout(timer);
    }, [subscribe === true]);
    useEffect(() => {
        props.getPlansRequest()
    }, [])
    return (
        <>
            <HelmetComponent />
            <section id="main" className="main-banner">
                <div className="container">
                    <div className="main-inner-banner">
                        <Slider {...mainSlide}>
                            <div>
                                <div className="banner-slide slide-1">
                                    <ScrollAnimation
                                        animateIn="animate__fadeIn"
                                        duration={0.3}
                                        className="slide-content"
                                    >
                                        <h2>Story Telling App For Children</h2>
                                        <p>
                                            Promote Imaginative Thinking With KidLitKatalyst AI
                                            Personalized Stories For Children
                                        </p>
                                        <span className="app_downloads_btn">
                                            <a href={PLAY_STORE} title="Play Store">
                                                <img
                                                    src={Playstore}
                                                    alt="Play store"
                                                    width={"auto"}
                                                    height={"auto"}
                                                />
                                            </a>
                                            <a href={APP_STORE} title="Apple store">
                                                <img
                                                    src={Applestore}
                                                    alt="Apple store"
                                                    width={"auto"}
                                                    height={"auto"}
                                                />
                                            </a>
                                        </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="zoomIn"
                                        duration={0.3}
                                        className="img-box"
                                    >
                                        <img src={BannerSlide} alt="Mobile Mockup" />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div>
                                <div className="banner-slide slide-2">
                                    <ScrollAnimation
                                        animateIn="animate__fadeIn"
                                        duration={0.3}
                                        className="slide-content"
                                    >
                                        <h2>
                                            Encourage Children To Explore And Engage In Creative
                                            Activities
                                        </h2>
                                        <p>
                                            Promote Imaginative Thinking With KidLitKatalyst AI
                                            Personalized Stories For Children
                                        </p>
                                        <span>
                                            <a href={PLAY_STORE} title="Play Store">
                                                <img
                                                    src={Playstore}
                                                    alt="Play store"
                                                    width={"auto"}
                                                    height={"auto"}
                                                />
                                            </a>
                                            <a href={APP_STORE} title="Apple store">
                                                <img
                                                    src={Applestore}
                                                    alt="Apple store"
                                                    width={"auto"}
                                                    height={"auto"}
                                                />
                                            </a>
                                        </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="zoomIn"
                                        duration={0.3}
                                        className="img-box"
                                    >
                                        <img
                                            src={BannerSlide2}
                                            alt="Mobile Mockup"
                                            width={"auto"}
                                            height={"auto"}
                                        />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div>
                                <div className="banner-slide slide-3">
                                    <ScrollAnimation
                                        animateIn="animate__fadeIn"
                                        duration={0.3}
                                        className="slide-content"
                                    >
                                        <h2>
                                            Children Writing Stories With A Platform To Illustrate
                                            Their Drawings
                                        </h2>
                                        <p>
                                            Promote Imaginative Thinking With KidLitKatalyst AI
                                            Personalized Stories For Children
                                        </p>
                                        <span>
                                            <a href={PLAY_STORE} title="Play Store">
                                                <img
                                                    src={Playstore}
                                                    alt="Play store"
                                                    width={"auto"}
                                                    height={"auto"}
                                                />
                                            </a>
                                            <a href={APP_STORE} title="Apple store">
                                                <img
                                                    src={Applestore}
                                                    alt="Apple store"
                                                    width={"auto"}
                                                    height={"auto"}
                                                />
                                            </a>
                                        </span>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="zoomIn"
                                        duration={0.3}
                                        className="img-box"
                                    >
                                        <img
                                            src={BannerSlide3}
                                            alt="Mobile Mockup"
                                            width={"auto"}
                                            height={"auto"}
                                        />
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </div>
            </section>

            <section className="about-us" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="img-box">
                                <ScrollAnimation animateIn="zoomIn" duration={0.3}>
                                    <img
                                        src={Aboutus}
                                        alt="Mockup"
                                        width={"auto"}
                                        height={"auto"}
                                    />
                                </ScrollAnimation>
                                <div className="shape-box">
                                    {/* <img src={AboutShape} alt='Round' /> */}
                                    <RounddotedSvg />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 overflow-hidden">
                            <ScrollAnimation
                                animateIn="animate__fadeInRight"
                                duration={0.3}
                                className="content-box"
                            >
                                <div className="common-title">
                                    <span className="badge-title">About Our App</span>
                                    <h2 className="title-box">About our App</h2>
                                </div>
                                <p>
                                    At KidLitKatalyst, we're on a mission to ignite young
                                    imaginations through the art of storytelling. Our innovative
                                    app blends the wonder of captivating tales with the precision
                                    of AI personalization, crafting unique adventures for every
                                    child. We believe that stories have the power to shape young
                                    minds, fostering creativity, empathy, and a passion for
                                    learning.{" "}
                                </p>
                                <p>
                                    With a commitment to safety, education, and entertainment, we
                                    invite children and parents to embark on a literary journey
                                    like no other.
                                </p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="about-us-shapes">
                    <img
                        src={BoatShape}
                        alt="Paper Boat"
                        width={"auto"}
                        height={"auto"}
                    />
                    {/* <BoatSvg /> */}
                    {/* <img src={PapperPlane} alt='Paper Plane' /> */}
                    <PapperPlaneSvg />
                </div>
            </section>

            <section className="process p-0">
                <div className="container">
                    <div className="process-inner text-center overflow-hidden">
                        <ScrollAnimation
                            animateIn="animate__fadeInDown"
                            duration={0.3}
                            className="common-title"
                        >
                            <span className="badge-title">Process</span>
                            <h2 className="title-box">Create your own story</h2>
                        </ScrollAnimation>
                        <div className="process-list">
                            <ScrollAnimation
                                animateIn="animate__fadeInLeft"
                                duration={0.3}
                                className="process-item"
                            >
                                <span>01</span>
                                <h3>Create your own prompt</h3>
                                <p>
                                    Craft your very own story with KidLitKatalyst! Take the reins
                                    of creativity and let your imagination soar. Begin by
                                    selecting your characters, choosing the perfect place,
                                    defining the genre, adding excitement, and setting the
                                    language tone. Your personalized story adventure awaits!
                                </p>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="animate__fadeIn"
                                duration={0.3}
                                className="process-item"
                            >
                                <span>02</span>
                                <h3>Embrace your unique taste</h3>
                                <p>
                                    In the world of storytelling, your preferences matter. With
                                    KidLitKatalyst, we celebrate your unique taste by creating
                                    stories tailored just for you. Our AI-powered process begins
                                    by understanding what captivates your imagination. So, why
                                    settle for ordinary tales when you can embrace your unique
                                    taste with KidLitKatalyst? Your next unforgettable story is
                                    just a click away.
                                </p>
                            </ScrollAnimation>
                            <ScrollAnimation
                                animateIn="animate__fadeInRight"
                                duration={0.3}
                                className="process-item"
                            >
                                <span>03</span>
                                <h3>Enjoy your wonderful story</h3>
                                <p>
                                    Finally, it's time to immerse yourself in the world of
                                    KidLitKatalyst. Sit back, relax, and enjoy the wonderful
                                    stories we've crafted just for you. It's a reading experience
                                    like no other, where every adventure is a reflection of your
                                    one-of-a-kind imagination.
                                </p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
                <div className="process-shapes">
                    {/* <img src={GirlDraw} alt='Girl draw' /> */}
                    <GirlDrawSvg />
                </div>
            </section>

            <section className="features" id="features">
                <div className="container">
                    <ScrollAnimation
                        animateIn="animate__fadeInDown"
                        duration={0.3}
                        className="common-title text-center"
                    >
                        <span className="badge-title">Features</span>
                        <h2 className="title-box">
                            Discovering the World a Visual Journey
                        </h2>
                        <p>
                            KidLitKatalyst is a groundbreaking app that leverages the
                            potential of artificial intelligence to provide a truly unique
                            storytelling journey for kids. Our AI-crafted stories will
                            transport your child into captivating adventures, immerse them in
                            imaginative realms, and kindle a newfound passion for the
                            extraordinary. Explore the exceptional features that distinguish
                            KidLitKatalyst.
                        </p>
                    </ScrollAnimation>
                    <div className="features-list">
                        <ScrollAnimation
                            animateIn="animate__fadeInLeft"
                            duration={0.3}
                            className="features-item"
                        >
                            <img
                                src={FeatureImg}
                                alt="Features Image"
                                width={"auto"}
                                height={"auto"}
                            />
                            <div className="content-box">
                                <h3>Create user account</h3>
                                <p>
                                    KidLitKatalyst makes it easy to create user accounts for
                                    children, parents, and all family members. Enjoy personalized
                                    storytelling adventures together, each tailored to individual
                                    preferences. Start your family's story-creation journey today!
                                </p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeInRight"
                            duration={0.3}
                            className="features-item"
                        >
                            <img
                                src={FeatureImg2}
                                alt="Features Image"
                                width={"auto"}
                                height={"auto"}
                            />
                            <div className="content-box">
                                <h3>Create Story</h3>
                                <p>
                                    Prepare to be amazed as our advanced AI algorithms create
                                    unique and captivating stories tailored to your child's
                                    interests, reading level, and preferences. Say goodbye to
                                    one-size-fits-all narratives – with KidLitKatalyst, every
                                    story is a personalized journey.
                                </p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeInLeft"
                            duration={0.3}
                            className="features-item"
                        >
                            <img
                                src={FeatureImg3}
                                alt="Features Image"
                                width={"auto"}
                                height={"auto"}
                            />
                            <div className="content-box">
                                <h3>Story Library</h3>
                                <p>
                                    Whether your child loves fairy tales, space adventures,
                                    mysteries, or historical quests, our diverse library of
                                    AI-generated stories covers a wide range of genres. We
                                    guarantee they'll find tales that match their wildest
                                    imaginations.
                                </p>
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeInRight"
                            duration={0.3}
                            className="features-item"
                        >
                            <img
                                src={FeatureImg4}
                                alt="Features Image"
                                width={"auto"}
                                height={"auto"}
                            />
                            <div className="content-box">
                                <h3>Story Player</h3>
                                <p>
                                    We believe in immersive storytelling. Our app brings stories
                                    to life through interactive elements that engage young
                                    adventures. They can interact with characters, make decisions
                                    that impact the plot, and be part of the magic unfolding on
                                    the pages.
                                </p>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="features-shapes">
                    {/* <img src={SunImg} alt='Sun' /> */}
                    <SunSvg />
                    <img src={TrainImg} alt="Train" width={"auto"} height={"auto"} />
                </div>
            </section>

            <section className="subscribe">
                <div className="container">
                    <div className="subscribe-inner">
                        <img
                            src={SubscribeMockup}
                            alt="Mobile Mockup"
                            width={"auto"}
                            height={"auto"}
                        />
                        <ScrollAnimation
                            animateIn="animate__fadeIn"
                            duration={0.3}
                            className="content-box"
                        >
                            <h3>
                                Encourage your kid to explore and engage in creative activities
                                to help increase their imagination!
                            </h3>
                            {subscribe ? (
                                <p className="animate__animated animate__fadeInRight thank_msg">
                                    Thank you for subscribing!
                                </p>
                            ) : (
                                <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
                                    {({ handleSubmit, values }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <div className="form-group">
                                                <Field
                                                    type="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="Enter your email"
                                                    name="email"
                                                />

                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    title="Subscribe"
                                                >
                                                    Subscribe
                                                </button>
                                            </div>
                                            {validator.current.message(
                                                __t("validations.auth.email"),
                                                values.email,
                                                "required",
                                                {
                                                    messages: {
                                                        required: __t("validations.auth.email"),
                                                    },
                                                }
                                            )}
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </ScrollAnimation>
                        <div className="subscribe-inner-shapes">
                            <img
                                src={Roundshape}
                                alt="shape"
                                width={"auto"}
                                height={"auto"}
                            />
                            <img src={Dropshape} alt="shape" width={"auto"} height={"auto"} />
                            <img
                                src={Dropshape2}
                                alt="shape"
                                width={"auto"}
                                height={"auto"}
                            />
                        </div>
                    </div>
                </div>
                <div className="subscribe-shapes">
                    {/* <img src={Airballoon} alt='Airballoon' /> */}
                    <AirballoonSvg />
                </div>
            </section>

            <section className="plans">
                <div className="container">
                    <ScrollAnimation
                        animateIn="animate__fadeInDown"
                        duration={0.3}
                        className="common-title text-center"
                    >
                        <span className="badge-title">Plans</span>
                        <h2 className="title-box">Our Popular Plans</h2>
                    </ScrollAnimation>
                    <div className="plans-list">
                        <ScrollAnimation
                            animateIn="animate__fadeInLeft"
                            duration={0.3}
                            className="card"
                        >
                            <h3>{planList[0]?.plan_name}</h3>
                            <span>₹{planList[0]?.price}</span>
                            <ul>
                                <li>
                                    <Ticksvg />
                                    Create {planList[0]?.total_story} stories
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[0]?.total_character} characters
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[0]?.total_place} Place
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[0]?.total_event} Event
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[0]?.total_scene} Scenes
                                </li>
                            </ul>
                            <a
                                type="submit"
                                className="btn btn-primary"
                                title="Get Started"
                                onClick={
                                    isDesktop ? handleScroll : undefined
                                }
                                target={isDesktop ? undefined : "_blank"}
                                href={isDesktop ? undefined : isApple}
                            >
                                Get Started
                            </a>
                            <div className="plans-inner-shapes">
                                <img
                                    src={PlansImg}
                                    alt="AirBalloon"
                                    width={"auto"}
                                    height={"auto"}
                                />
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeIn"
                            duration={0.3}
                            className="card"
                        >
                            <h3>{planList[1]?.plan_name}</h3>
                            <span>₹{planList[1]?.price}</span>
                            <ul>
                                <li>
                                    <Ticksvg />
                                    Create {planList[1]?.total_story} stories
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[1]?.total_character} characters
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[1]?.total_place} Place
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[1]?.total_event} Event
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[1]?.total_scene} Scenes
                                </li>
                            </ul>
                            <a
                                type="submit"
                                className="btn btn-primary"
                                title="Get Started"
                                onClick={
                                    isDesktop ? handleScroll : undefined
                                }
                                target={isDesktop ? undefined : "_blank"}
                                href={isDesktop ? undefined : isApple}
                            >
                                Get Started
                            </a>
                            <div className="plans-inner-shapes">
                                <img
                                    src={PlansImg2}
                                    alt="AirBalloon"
                                    width={"auto"}
                                    height={"auto"}
                                />
                            </div>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeInRight"
                            duration={0.3}
                            className="card"
                        >
                            <h3>{planList[2]?.plan_name}</h3>
                            <span>₹{planList[2]?.price}</span>
                            <ul>
                                <li>
                                    <Ticksvg />
                                    Create {planList[2]?.total_story} stories
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[2]?.total_character} characters
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[2]?.total_place} Place
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[2]?.total_event} Event
                                </li>
                                <li>
                                    <Ticksvg />
                                    Add {planList[2]?.total_scene} Scenes
                                </li>

                            </ul>
                            <a
                                type="submit"
                                onClick={
                                    isDesktop ? handleScroll : undefined
                                }
                                target={isDesktop ? undefined : "_blank"}
                                href={isDesktop ? undefined : isApple}
                                className="btn btn-primary"
                                title="Get Started"
                            >
                                Get Started
                            </a>
                            <div className="plans-inner-shapes">
                                <img
                                    src={PlansImg3}
                                    alt="AirBalloon"
                                    width={"auto"}
                                    height={"auto"}
                                />
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
                <div className="plans-shapes">
                    {/* <img src={GirlPlay} alt='Girl Img' /> */}
                    <GirlPlaySvg />
                </div>
            </section>

            <section className="testimonials">
                <ScrollAnimation
                    animateIn="animate__fadeInDown"
                    duration={0.3}
                    className="common-title text-center"
                >
                    <span className="badge-title">Reviews from our Users</span>
                    <h2 className="title-box">
                        What do they say after using the KidLitKatalyst
                    </h2>
                </ScrollAnimation>
                <ScrollAnimation
                    animateIn="animate__fadeInRight"
                    duration={0.3}
                    className="testimonials-list"
                >
                    <Slider {...testimonialSlide}>
                        <div>
                            <div className="card">
                                <QuoteSvg />
                                <p>
                                    The graphics are really nice, and attention-grabbing for
                                    children without too many complications. The narration is
                                    clear and well-paced, and overall a good storytelling
                                    experience.
                                </p>
                                <div className="testimonial-user">
                                    <div>
                                        <h3>Ameya Pingaley</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <QuoteSvg />
                                <p>
                                    We have all read storybooks to children, but this app
                                    encourages children to write their own narrative to make
                                    choices that shape the story's outcome it reveals hidden
                                    surprises, the interactive features add an extra layer of
                                    engagement and storytelling becomes an adventure.
                                </p>
                                <div className="testimonial-user">
                                    <div>
                                        <h3>Rohan Maske</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="card">
                                <QuoteSvg />
                                <p>
                                    As a parent, I like the app's user-friendliness for children.
                                    It improves literature skills and is fun to see the smiles on
                                    my kid's faces when they hear the stories out loud. It's so
                                    much fun to use.
                                </p>
                                <div className="testimonial-user">
                                    <div>
                                        <h3>Narayan Satoskar</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div>
                            <div className='card'>
                                <QuoteSvg />
                                <p>Nunc pharetra metus at imperdiet molestie. Integer ut malesuada augue, ut aliquet nulla. Integer in augue tellus.</p>
                                <div className='testimonial-user'>
                                    <img src={UserImg4} alt='User' width={'auto'} height={'auto'} />
                                    <div>
                                        <h3>Amani Stoltenberg</h3>
                                        <span>Developer</span>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </Slider>
                </ScrollAnimation>
            </section>

            <section className="call-to-action" ref={tableRef}>
                <div className="container">
                    <div className="call-to-action-inner">
                        <ScrollAnimation
                            animateIn="animate__fadeIn"
                            duration={0.3}
                            className="content-box"
                        >
                            <h1>Best Bedtime Story App for Your Kids</h1>
                            <p>
                                Explore a world of storytelling with us and unlock the magic of
                                your child's favorite tale. KidLitKatalyst helps you uncover the
                                stories that captivate your little one's heart and imagination.
                                Let's embark on a journey to find their perfect story!
                            </p>
                            <span>
                                <a href={PLAY_STORE} title="Play Store">
                                    <img
                                        src={Playstore}
                                        alt="Play store"
                                        width={"auto"}
                                        height={"auto"}
                                    />
                                </a>
                                <a href={APP_STORE} title="Apple store">
                                    <img
                                        src={Applestore}
                                        alt="Apple store"
                                        width={"auto"}
                                        height={"auto"}
                                    />
                                </a>
                            </span>
                        </ScrollAnimation>
                        <img
                            src={CalltoactionMockup}
                            alt="Mobile Mockup"
                            width={"auto"}
                            height={"auto"}
                        />
                    </div>
                </div>
                <div className="call-to-action-shapes">
                    {/* <img src={BirdShape} alt='shape' /> */}
                    <BirdSvg />
                    <img src={PlaneShape} alt="shape" />
                </div>
            </section>
        </>
    );
}
const mapStateToProps = ({ landingPage, planList }) => {
    return {
        subStatus: landingPage?.emailData?.status
            ? landingPage.emailData.status
            : false,
        planList: planList?.getPlans?.list.length > 0 ? planList.getPlans.list : []
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        SendEmailRequest: (data) => dispatch(sendemailRequest(data)),
        getPlansRequest: (data) => dispatch(getPlansRequest(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
