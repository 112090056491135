import React from 'react'
import { CallSvg, MailSvg } from '../../components/Common/CommonSvg'
import { FacebookwhiteSvg, InstaSvg, LinkedinwhiteSvg, LocationSvg, TopArrowSvg, TwitterSvg, VimeoSvg, YoutubeSvg } from '../../components/Common/CommonFrontSvg'
import WhiteLogo from "../../assets/front-images/white-logo.webp";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link, NavLink } from 'react-router-dom';

export default function Footer({ isVisible, scrollToTop }) {
    return (
        <>
            <footer>
                <div className='container'>
                    <div className='row'>
                        <ScrollAnimation animateIn="animate__fadeInLeft" className='col-6 col-md-3'>
                            <Link href='/' className='logo' title='KidLitKatalyst'>
                                <img src={WhiteLogo} alt='KidLitKatalyst' />
                            </Link>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInLeft" className='col-6 col-md-2'>
                            <h2>Quick links</h2>
                            <ul className='quick-links'>
                                <li>
                                    <NavLink to='/' title='Home'>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/aboutus' title='About us'>About us</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/features' title='Feature'>Features</NavLink>
                                </li>
                            </ul>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInRight" className='col-6 col-md-3'>
                            <h2>Policies</h2>
                            <ul>
                                <li>
                                    <NavLink to='/privacy-policy' title='Privacy policy'>Privacy policy</NavLink>
                                </li>
                                <li>
                                    <NavLink to='/termsconditions' title='Terms & conditions'>Terms & conditions</NavLink>
                                </li>
                            </ul>
                        </ScrollAnimation>
                        <ScrollAnimation animateIn="animate__fadeInRight" className='col-6 col-md-4'>
                            <h2>Contact us on</h2>
                            <ul className='contact-list'>
                                <li>
                                    <div className='call-detail'>
                                        <LocationSvg />
                                        <span>                                        
                                            Office # 1, 2nd Floor, Ashley Towers, Kanakia Rd, Vagad Nagar, Beverly Park, Mira Road East, Mira Bhayandar, Maharashtra 401107
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div className='call-detail'>
                                        <CallSvg />
                                        <span>
                                            <a href='tel:+91 788-83-83-788' title='+91 788-83-83-788'>
                                                +91 788-83-83-788
                                            </a>
                                            <a href='tel:+91 84-889-889-84' title='+91 84-889-889-84'>
                                                +91 84-889-889-84
                                            </a>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <a href='mailto:kidlitkatalyst@cinutedigital.com' title='kidlitkatalyst@cinutedigital.com'>
                                        <MailSvg />
                                        kidlitkatalyst@cinutedigital.com
                                    </a>
                                </li>
                            </ul>
                        </ScrollAnimation>
                    </div>
                    <div className='copyright'>
                        <p>Copyright © {new Date().getFullYear()} KidLitKatalyst</p>
                        <ul className='m-0 p-0'>
                            <li>
                                <a href='https://twitter.com/Kidlitkatalyst' target='_blank' title='Twitter'>
                                    <TwitterSvg />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.instagram.com/kidlitkatalyst/' target='_blank' title='Instagram'>
                                    <InstaSvg />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.facebook.com/kidlitkatalyst/' target='_blank' title='Facebook'>
                                    <FacebookwhiteSvg />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.linkedin.com/company/kid-lit-katalyst/' target='_blank' title='Linkedin'>
                                    <LinkedinwhiteSvg />
                                </a>
                            </li>
                            <li>
                                <a href='https://www.youtube.com/@KidLitKatalyst' target='_blank' title='YouTube'>
                                    <YoutubeSvg />
                                </a>
                            </li>
                            <li>
                                <a href='https://vimeo.com/kidlitkatalyst' target='_blank' title='Vimeo'>
                                    <VimeoSvg />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>

            <button className={isVisible ? 'back-to-top back-to-top-button slide-up-down' : 'back-to-top back-to-top-button'} //onClick={scrollToTop}
                onClick={() => {
                    if (isVisible) {
                        scrollToTop()
                    }

                }}
            >
                <TopArrowSvg />
            </button>
        </>
    )
}
