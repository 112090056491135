export const getValueByKey = (key) => {
  return localStorage.getItem(key);
};
export const getUserName = () => {
  return localStorage.getItem("fullName");
};
export const removeLoggedInUserDetails = () => {
  localStorage.clear();
};
export const setLoginUserData = (data) => {
  localStorage.setItem("email", JSON.stringify(data.data.user.email));
  localStorage.setItem("userId", JSON.stringify(data.data.user.userId));
  localStorage.setItem("accessToken", data?.data?.accessToken);
  localStorage.setItem("fullName", data?.data?.user?.fullName);
};
export const setForgotUserData = (data) => {
  localStorage.setItem("accessToken", JSON.stringify(data.data.accessToken));
};

export const updateCurrentPageName = (Name) => {
  localStorage.setItem("currentPage", Name);
  const pageTitleElement = document.getElementById("pageTitle");
  if (pageTitleElement) {
    pageTitleElement.innerHTML = Name;
  }
};

