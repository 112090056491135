import React from "react";
import { Link } from "react-router-dom";
import HelmetComponent from "../Seo/HelmetComponent";

const NotFound = () => {
    return (
        <>
            <HelmetComponent />
            <div className="error-page">
                <div className="error-content">
                    <h1>404 <span>Oops! Page Not Found</span></h1>
                    {/* <p>The page you are looking for does not exist.</p> */}
                    <Link to={"/home"} className="btn btn-primary" type="submit" title='Back To Dashboard'>
                        Back to Dashboard
                    </Link>
                </div>
            </div>
        </>

    );
};

export default NotFound;
