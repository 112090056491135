import React, { useState } from "react";
import { StarSvg } from "../../components/Common/CommonFrontSvg";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";
import HelmetComponent from "../../components/Seo/HelmetComponent";

function TermsConditions(props) {
  // const [isOpen, setIsOpen] = useState(false);
  // const toggle = () => setIsOpen(!isOpen);

  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <>
      <HelmetComponent />
      <section className="term-conditions-baneer p-0">
        <div className="container">
          <div className="inner-page-banner">
            <h1>Terms & Conditions</h1>
            {props.brodcum !== true && (
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="/">Home</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>Terms & Conditions</BreadcrumbItem>
              </Breadcrumb>
            )}
            <div className="shape-box">
              <StarSvg />
              <StarSvg />
            </div>
          </div>
        </div>
      </section>

      <section className="terms-conditions-details">
        <div className="container">
          <p>
            Effective date: [4th Sep 2023]Welcome to KidLitKatalyst! By
            accessing or using our AI-generated stories mobile application and
            services ("App"), you agree to abide by these Terms of Use. Please
            read these terms carefully before using our App. If you do not agree
            to these terms, you should not access or use the App.
          </p>
          <Accordion
            open={open}
            toggle={toggle}
            className="terms-conditions-details-list pb-2 m-0"
          >
            <AccordionItem>
              <AccordionHeader targetId="1">
                License and Restrictions
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <ul>
                  <li>
                    KidLitKatalyst grants you a limited, non-exclusive,
                    non-transferable, and revocable license to access and use
                    the App for personal, non-commercial purposes.
                  </li>
                  <li>
                    The App and its content are protected by copyright,
                    trademark, and other intellectual property laws. All rights
                    not expressly granted are reserved by KidLitKatalyst.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="2"
                className="terms-conditions-list-title"
              >
                User Account and Registration
              </AccordionHeader>
              <AccordionBody accordionId="2">
                <ul>
                  <li>
                    To access certain features of the App, you may be required
                    to create a user account. You are responsible for providing
                    accurate and complete information during the registration
                    process.
                  </li>
                  <li>
                    You agree to keep your account credentials confidential and
                    not share them with third parties. You are responsible for
                    all activities that occur under your account.
                  </li>
                  <li>
                    If you believe there has been unauthorized access to your
                    account, please notify us immediately on{" "}
                    <a
                      href="mailto:kidlitkatalyst@cinutedigital.com"
                      title="kidlitkatalyst@cinutedigital.com"
                    >
                      'kidlitkatalyst@cinutedigital.com'
                    </a>
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="3"
                className="terms-conditions-list-title"
              >
                Age Restrictions
              </AccordionHeader>
              <AccordionBody accordionId="3">
                <ul>
                  <li>
                    KidLitKatalyst is intended for used by children and young
                    minds with the involvement and consent of parents or
                    guardians.
                  </li>
                  <li>
                    By using the App, you affirm that you are either over the
                    age of 18 or have parental or guardian consent to use the
                    App.
                  </li>
                  <li>
                    If you are a parent or guardian, you are responsible for
                    monitoring and supervising your child's use of the App.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="4"
                className="terms-conditions-list-title"
              >
                Content Guidelines
              </AccordionHeader>
              <AccordionBody accordionId="4">
                <ul>
                  <li>
                    You agree not to upload, post, or transmit any content that
                    is unlawful, harmful, defamatory, abusive, or otherwise
                    objectionable.
                  </li>
                  <li>
                    KidLitKatalyst reserves the right to remove or take legal
                    action if any content that violates these terms or is deemed
                    inappropriate.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="5"
                className="terms-conditions-list-title"
              >
                Privacy Policy
              </AccordionHeader>
              <AccordionBody accordionId="5">
                <ul>
                  <li>
                    Your use of the App is subject to our Privacy Policy, which
                    explains how we collect, use, disclose, and safeguard your
                    data. By using the App, you consent to the practices
                    described in the Privacy Policy.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="6"
                className="terms-conditions-list-title"
              >
                Modifications to the App
              </AccordionHeader>
              <AccordionBody accordionId="6">
                <ul>
                  <li>
                    KidLitKatalyst may update, modify, or discontinue the App or
                    its features at any time without prior notice.
                  </li>
                  <li>
                    We reserve the right to suspend or terminate your access to
                    the App for violation of these Terms of Use.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="7"
                className="terms-conditions-list-title"
              >
                Third-Party Links
              </AccordionHeader>
              <AccordionBody accordionId="7">
                <ul>
                  <li>
                    The App may contain links to third-party websites or
                    services. KidLitKatalyst is not responsible for the content
                    or practices of these third-party sites.
                  </li>
                  <li>
                    Your use of third-party links is at your own risk, and you
                    should review their respective terms and privacy policies.
                  </li>
                  <li>
                    KidLitKatalyst does not guarantee the accuracy,
                    completeness, or reliability of the App's content.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="8"
                className="terms-conditions-list-title"
              >
                Limitation of Liability
              </AccordionHeader>
              <AccordionBody accordionId="8">
                <ul>
                  <li>
                    KidLitKatalyst and its affiliates shall not be liable for
                    any direct, indirect, incidental, special, or consequential
                    damages arising out of your use or inability to use the App.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem>
              <AccordionHeader
                targetId="9"
                className="terms-conditions-list-title"
              >
                Governing Law
              </AccordionHeader>
              <AccordionBody accordionId="9">
                <ul>
                  <li>
                    These Terms of Use shall be governed by and construed in
                    accordance with the laws of Indian Jurisdiction.
                  </li>
                  <li>
                    Any disputes arising from these terms shall be subject to
                    the exclusive jurisdiction of the courts in India.
                  </li>
                  <li>
                    By using KidLitKatalyst, you acknowledge that you have read,
                    understood, and agreed to these Terms of Use. If you have
                    any questions or concerns, please contact us at{" "}
                    <a
                      href="mailto:kidlitkatalyst@cinutedigital.com"
                      title="kidlitkatalyst@cinutedigital.com"
                    >
                      kidlitkatalyst@cinutedigital.com
                    </a>
                  </li>
                  <li>
                    Thank you for choosing KidLitKatalyst! We hope you enjoy the
                    magical world of AI-generated stories and embark on
                    unforgettable reading adventures with your child.
                  </li>
                </ul>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
          <p>
            Thank you for choosing KidLitKatalyst! We are committed to providing
            you and your child with an enriching and safe storytelling
            experience.
          </p>
        </div>
      </section>
    </>
  );
}

export default TermsConditions;
