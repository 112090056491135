import Swal from "sweetalert2";
const ApiResponseMessage = async (message, status) => {

  // debugger;
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: status,
    title: message,
  });

  return Toast;
};

export { ApiResponseMessage };
