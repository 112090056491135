import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
const urlList = [
    {
        url: '/',
        title: 'Bedtime Stories for Kids App | Kidlitkatalyst',
        description: 'We offers a captivating collection of bedtime stories, ranging from timeless classics to modern gems. The story is brought to life through interactive elements.',
        keywords: 'bedtime stories app | bedtime stories | bedtime stories for kids | short bed time story | sleep stories | short bedtime stories | sleep stories app | sleep stories | sleep stories for adults | bedtime stories for adults',
    },
    {
        url: '/aboutus',
        title: 'Know More About KidLitKatalyst',
        description: 'With our personalized storyteller app ‘KidLitKatalyst’, we want to redefine the storytelling experience for the young generation.',
        keywords: 'bedtime stories app | bedtime stories | bedtime stories for kids | short bed time story | sleep stories | short bedtime stories | sleep stories app | sleep stories | sleep stories for adults | bedtime stories for adults',
    },
    {
        url: '/features',
        title: 'Know More Features About KidLitKatalyst',
        description: 'Know more about our mobile app features in detail which will help you to understand and benefit yourself fruitfully',
        keywords: 'bedtime stories app | bedtime stories | bedtime stories for kids | short bed time story | sleep stories | short bedtime stories | sleep stories app | sleep stories | sleep stories for adults | bedtime stories for adults',
    },
    {
        url: '/getapp',
        title: 'Get Application for Bedtime Stories | Kidlitkatalyst',
        description: 'Download the Kidlitkatalyst app for awesome bedtime stories, ranging from timeless classics to modern gems.',
        keywords: 'bedtime stories app | bedtime stories | bedtime stories for kids | short bed time story | sleep stories | short bedtime stories | sleep stories app | sleep stories | sleep stories for adults | bedtime stories for adults',
    },
    {
        url: '/privacy-policy',
        title: 'Privacy Policy | Kidlitkatalyst',
        description: 'This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our AI-generated stories mobile application',
        keywords: 'bedtime stories app | bedtime stories | bedtime stories for kids | short bed time story | sleep stories | short bedtime stories | sleep stories app | sleep stories | sleep stories for adults | bedtime stories for adults',
    },
    {
        url: '/termsconditions',
        title: 'Terms & Conditions | Kidlitkatalyst',
        description: 'Please read these terms carefully before using our App. If you do not agree to these terms, you should not access or use the App.',
        keywords: 'bedtime stories app | bedtime stories | bedtime stories for kids | short bed time story | sleep stories | short bedtime stories | sleep stories app | sleep stories | sleep stories for adults | bedtime stories for adults',
    },
]

function HelmetComponent() {
    const location = useLocation()
    useEffect(() => {
        let headerData = urlList?.filter(
            (x) => x?.url === '/' + location.pathname.split('/')[1],
        )?.[0]
        if (headerData?.url) {
            document.title = headerData.title;
            document.getElementsByTagName("META")[2].content = headerData.description;
            document.getElementById('canonicalLink').href = location.pathname;
            document.getElementsByTagName("META")[4].content = headerData.keywords;
        } else {
            document.title = "Not Found";
            document.getElementsByTagName("META")[2].content = "Not Found";
            document.getElementById('canonicalLink').href = location.pathname;
            document.getElementsByTagName("META")[4].content = "Not Found"

        }
    }, [location.pathname])
    return (
        <>
        </>
    )
}
export default (HelmetComponent)