import React, { useState, useEffect } from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import Playstore from "../../assets/front-images/google-play.webp";
import Applestore from "../../assets/front-images/apple-store.webp";
import CalltoactionMockup from "../../assets/front-images/call-to-action-mockup.webp";
import PlaneShape from "../../assets/front-images/plan-shape.webp";
import AboutUsmockup from "../../assets/front-images/about-us-page-mockup.webp";
import { AboutussmallSvg, BirdSvg, MissionSvg, RoundlineSvg, StarSvg } from '../../components/Common/CommonFrontSvg';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import HelmetComponent from '../../components/Seo/HelmetComponent';
import { useScroll } from '../../helper/scrollhelper';
import { APP_STORE, PLAY_STORE } from '../../redux/constant/authConstants';
function AboutUs(props) {
    const { tableRef } = useScroll();
    return (
        <>
            <HelmetComponent />
            <section className='about-us-baneer p-0'>
                <div className='container'>
                    <div className='inner-page-banner'>
                        <h1>About us</h1>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <a href="/">
                                    Home
                                </a>
                            </BreadcrumbItem>
                            <BreadcrumbItem active>
                                About us
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className='shape-box'>
                            <StarSvg />
                            <StarSvg />
                        </div>
                    </div>
                </div>
            </section>

            <section className='main-about-us'>
                <div className="container">
                    <div className='row'>
                        <div className='col-6'>
                            <div className='img-box'>
                                <img src={AboutUsmockup} alt='Mockup' />
                                <div className='shape-box'>
                                    <AboutussmallSvg />
                                    <RoundlineSvg />
                                </div>
                            </div>
                        </div>
                        <div className='col-6 overflow-hidden'>
                            <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight" className='content-box'>
                                <div className='common-title'>
                                    <span className='badge-title'>About us</span>
                                    <h2 className='title-box'>Know more about <br /> KidLitKatalyst</h2>
                                </div>
                                <p>We all remember how our parents and grand parents used to tell us bedtime stories that we all loved. With our personalized storyteller app ‘KidLitKatalyst’, we want to redefine the storytelling experience for the young generation.</p>
                                <p>We have revolutionizing the world of children's literature with our AI-generated stories. We believe in harnessing the power of artificial intelligence to create captivating and personalized narratives that ignite the imaginations of young minds.</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </section>

            <section className="our-mission p-0">
                <div className="container">
                    <div className='our-mission-inner overflow-hidden'>
                        <div className='row align-items-center'>
                            <div className='col-6'>
                                <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft" className='content-box'>
                                    <div className='common-title'>
                                        <span className='badge-title'>Our Mission</span>
                                        <h2 className='title-box'>Unleashing Imaginations, One Tale at a Time</h2>
                                    </div>
                                    <p>Our mission is to redefine storytelling by leveraging cutting-edge technology. We aim to provide children with unique and engaging stories that are tailored to their individual interests and reading levels.</p>
                                    <p>Through KidLitKatalyst, We strive to enhance comprehension, pronunciation, and vocabulary, while also sparking the imaginations and inspiring the creativity of our young audience.</p>
                                </ScrollAnimation>
                            </div>
                            <div className='col-6'>
                                <div className='img-box'>
                                    <ScrollAnimation duration={0.3} animateIn="zoomIn">
                                        <MissionSvg />
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="we-offer">
                <div className='container overflow-hidden'>
                    <ScrollAnimation duration={0.3} animateIn="animate__fadeInDown" className='common-title text-center'>
                        <span className='badge-title'>What We Offer</span>
                        <h2 className='title-box'>Creative Storytelling Made Simple</h2>
                        <p>Join KidLitKatalyst today and unlock a world of innovative AI-generated stories that will captivate and inspire your child's imagination. Let's revolutionize Storytelling for the next generation!</p>
                    </ScrollAnimation>
                    <div className='we-offer-list'>
                        <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft" className='we-offer-detail-item'>
                            <span>01</span>
                            <h4>Personalized Stories</h4>
                            <p>KidLitKatalyst offers a vast library of innovative stories that cover a wide range of genres and themes. Our advanced algorithms analyze various data points, including children's preferences, habits, and feedback, to generate personalized narratives that captivate and entertain. With our smart storytelling app, every child can embark on an exciting literary journey that is specifically designed for them.</p>
                        </ScrollAnimation>
                        <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight" className='we-offer-detail-item'>
                            <span>02</span>
                            <h4>Progress Tracking and Analytics</h4>
                            <p>With our app, parents and caregivers can easily monitor their child's reading progress. Our analytics provide insights into reading habits, comprehension levels, and areas of interest. This information empowers parents to support their child's journey and engage in meaningful discussions about the stories they explore.</p>
                        </ScrollAnimation>
                        <ScrollAnimation duration={0.3} animateIn="animate__fadeInLeft" className='we-offer-detail-item'>
                            <span>03</span>
                            <h4>Interactive Audio Experience</h4>
                            <p>We understand the importance of active engagement while reading. Our app incorporates interactive elements that allow children to immerse themselves in the story. They can interact with characters, make choices that influence the plot, and explore visually stunning illustrations. Through this interactive reading experience, children become active participants in the story, enhancing their enjoyment and comprehension.</p>
                        </ScrollAnimation>
                        <ScrollAnimation duration={0.3} animateIn="animate__fadeInRight" className='we-offer-detail-item'>
                            <span>04</span>
                            <h4>Safe and Secure Environment</h4>
                            <p>We prioritize the safety and privacy of our young readers. Our app incorporates robust security measures to ensure a safe digital space. We adhere to strict data protection protocols and offer parental controls that allow caregivers to manage and supervise their child's reading experience.</p>
                        </ScrollAnimation>
                    </div>
                </div>
            </section>

            <section className='call-to-action' ref={tableRef} >
                <div className='container'>
                    <div className='call-to-action-inner'>
                        <ScrollAnimation duration={0.3} animateIn="animate__fadeIn" className='content-box'>
                            <h3>Discover Your Child's Favorite Story!</h3>
                            <p>Explore a world of storytelling with us and unlock the magic of your child's favorite tale. KidLitKatalyst helps you uncover the stories that captivate your little one's heart and imagination. Let's embark on a journey to find their perfect story!</p>
                            <span>
                                <a href={PLAY_STORE} title='Play Store'>
                                    <img src={Playstore} alt='Play store' width={'auto'} height={'auto'} />
                                </a>
                                <a href={APP_STORE} title='Apple store'>
                                    <img src={Applestore} alt='Apple store' width={'auto'} height={'auto'} />
                                </a>
                            </span>
                        </ScrollAnimation>
                        <img src={CalltoactionMockup} alt='Mobile Mockup' />
                    </div>
                </div>
                <div className='call-to-action-shapes'>
                    {/* <img src={BirdShape} alt='shape' /> */}
                    <BirdSvg />
                    <img src={PlaneShape} alt='shape' />
                </div>
            </section>
        </>

    )
}


export default AboutUs