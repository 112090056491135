import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SEND_EMAIL_REQUEST } from "./type";
import { sendemailSuccess, } from "./action";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER, SUCCESS } from "../constant/commonConstants";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper"
function* getEmailData({ payload: data }) {
    let response;
    try {
        response = yield call(invokeApi, "post", `/webSubscription`, data);
        if (response.status) {
            yield put(sendemailSuccess(response));
            // ApiResponseMessage(response.message, SUCCESS)
            
        } else {
            yield put(handleApiCallException(response.data));
            ApiResponseMessage(response.message, DANGER)
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* watchEmailDataRequest() {
    yield takeEvery(SEND_EMAIL_REQUEST, getEmailData);
}
function* LandingPageSaga() {
    yield all([fork(watchEmailDataRequest)]);
}

export default LandingPageSaga;
