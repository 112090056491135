// Your scroll helper file (e.g., scrollhelper.js)
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { APP_STORE, PLAY_STORE } from "../redux/constant/authConstants";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const tableRef = useRef(null);
  const [isApple, setIsApple] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);

  const location = useLocation();

  const handleScroll = () => {
    if (tableRef.current) {
      let position = tableRef.current.offsetTop;
      window.scrollTo({
        top: position,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/android/.test(userAgent)) {
      setIsApple(PLAY_STORE);
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      setIsApple(APP_STORE);
    } else {
      if (
        location.pathname === "/privacy-policy" ||
        location.pathname === "/termsconditions"
      ) {
        setIsDesktop(false);
        setIsApple(PLAY_STORE);
      } else {
        setIsDesktop(true);
      }
    }
  }, [location.pathname]);

  return (
    <ScrollContext.Provider
      value={{ tableRef, handleScroll, isApple, isDesktop }}
    >
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }
  return context;
};
