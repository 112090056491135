import { SEND_EMAIL_REQUEST, SEND_EMAIL_SUCCESS } from "./type"

export const sendemailRequest = (data) => ({
    type: SEND_EMAIL_REQUEST,
    payload: data
}
)
export const sendemailSuccess = (data) => ({
    type: SEND_EMAIL_SUCCESS,
    payload: data
})

