import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { GET_PLANS_REQUEST } from "./type";
import {  getPlansSuccess } from "./action";
import { ApiResponseMessage } from "../../helper/tosterHelpers";
import { DANGER } from "../constant/commonConstants";
import { handleApiCallException } from "../shared/action";
import { invokeApi } from "../../enverment/axiosHelper"
function* getPlanData() {
    let response;
    try {
        response = yield call(invokeApi, "get", `/planList`);
        if (response.status) {
            yield put(getPlansSuccess(response.data));
            // ApiResponseMessage(response.message, SUCCESS)
        } else {
            yield put(handleApiCallException(response.data));
            ApiResponseMessage(response.message, DANGER)
        }
    } catch (error) {
        yield put(handleApiCallException(error));
    }
}
function* watchPlanRequest() {
    yield takeEvery(GET_PLANS_REQUEST, getPlanData);
}
function* plansSaga() {
    yield all([fork(watchPlanRequest)]);
}

export default plansSaga;
