import { combineReducers } from "redux";
import sharedReducer from "./shared/reducer";
import landingPageReducer from "./landingpage/reducer";
import planReducer from "./plans/reducer";

export const rootReducer = combineReducers({
    sharedReducer: sharedReducer,
    landingPage: landingPageReducer,
    planList: planReducer,
});
