import React, { useEffect, useState } from "react";
import Logo from "../../assets/front-images/logo.webp";
import { Button, NavItem } from "reactstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  FacebookwhiteSvg,
  InstaSvg,
  LinkedinwhiteSvg,
  TwitterSvg,
  VimeoSvg,
  YoutubeSvg,
} from "../../components/Common/CommonFrontSvg";
import { useScroll } from "../../helper/scrollhelper";

export default function Header({ isSidebar, handleSidebarOpen }) {
  const scrollView = useScroll();

  return (
    <>
      <header className={isSidebar === true ? "menu-open" : ""}>
        <nav className="navbar">
          <div className="container">
            <a className="navbar-brand p-0 m-0" title="KidLitKatalyst" href="/">
              <img src={Logo} alt="KidLitKatalyst" />
            </a>
            <ul className={`nav justify-content-end }`}>
              {/* <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    aria-current="page"
                                    href="#main"
                                    title="Home"
                                >
                                    Home
                                </a>
                            </li> */}
              <NavItem>
                <NavLink className={"nav-link"} to={"/"}>
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={"nav-link"} to={"/aboutus"}>
                  About us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={"nav-link"} to={"/features"}>
                  Features
                </NavLink>
              </NavItem>
              {/* <li className="nav-item">
                                <a className="nav-link" href="#about" title="About us">
                                    About us
                                </a>
                            </li> */}
              {/* <li className="nav-item">
                                <a className="nav-link" href="#features" title="Features">
                                    Features
                                </a>
                            </li> */}
              <li className="social-icons">
                <ul className="m-0 p-0">
                  <li>
                    <a
                      href="https://twitter.com/Kidlitkatalyst"
                      target="_blank"
                      title="Twitter"
                    >
                      <TwitterSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/kidlitkatalyst/"
                      target="_blank"
                      title="Instagram"
                    >
                      <InstaSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/kidlitkatalyst/"
                      target="_blank"
                      title="Facebook"
                    >
                      <FacebookwhiteSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/kid-lit-katalyst/"
                      target="_blank"
                      title="Linkedin"
                    >
                      <LinkedinwhiteSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/@KidLitKatalyst"
                      target="_blank"
                      title="YouTube"
                    >
                      <YoutubeSvg />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://vimeo.com/kidlitkatalyst"
                      target="_blank"
                      title="Vimeo"
                    >
                      <VimeoSvg />
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item get-app-btn">
                <a
                  className="btn btn-primary"
                  title="Get App"
                  onClick={
                    scrollView.isDesktop ? scrollView.handleScroll : undefined
                  }
                  target={scrollView.isDesktop ? undefined : "_blank"}
                  href={scrollView.isDesktop ? undefined : scrollView.isApple}
                >
                  Get App
                </a>
              </li>
            </ul>
            <button onClick={handleSidebarOpen} className="menu-box">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </nav>
      </header>
    </>
  );
}
