import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Outlet, useLocation } from "react-router-dom";
import { LoaderSvg } from "../../components/Common/CommonSvg";
import { ScrollProvider } from "../../helper/scrollhelper";

export default function LayoutFront() {
  const location = useLocation();
  const [isSidebar, setIsSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const handleSidebarOpen = () => {
    setIsSidebar(!isSidebar);
  };

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 150) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    if (isLoading) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isLoading]);
  useEffect(() => {
    if (isSidebar) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isSidebar])
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);
  useEffect(() => {
    setIsLoading(true);
    // if (location.pathname !== "/privacy-policy") {
    scrollToTop();
    // }
    setIsSidebar(false);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, [location.pathname]);
  return (
    <ScrollProvider>
      <div className={isVisible ? "front-wrapper header-fix" : "front-wrapper"}>
        {isLoading === true && (
          <div className="pre_loader">
            <LoaderSvg />
          </div>
        )}
        <Header isSidebar={isSidebar} handleSidebarOpen={handleSidebarOpen} />
        <Outlet />
        <Footer isVisible={isVisible} scrollToTop={scrollToTop} />
      </div>
    </ScrollProvider>
  );
}
