import { all } from "redux-saga/effects";
import LandingPageSaga from "./landingpage/saga";
import plansSaga from "./plans/saga";


function* rootSaga() {
    yield all([
        LandingPageSaga(),
        plansSaga()
    ]);
}
export default rootSaga;
