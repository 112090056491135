

import axios from "axios";
import { removeLoggedInUserDetails, getValueByKey } from "../utils/authUtils";
import * as authConstants from "../redux/constant/authConstants";
const API_URL = window.location.hostname === "localhost"
    ? process.env.REACT_APP_API
    : process.env.REACT_APP_API_PRODUCTION;
const invokeApi = (method, url, data, headers) => {
    const baseUrl = API_URL;
    url = baseUrl + url;
    return axios({
        method: method,
        url: url,
        headers: {
            ...headers,
            Authorization: "Bearer " + getValueByKey(authConstants.TOKEN),
        },
        data: data,
    })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            return err.response.data;
        });
};


axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (
            error.response.status === 401 &&
            window.location.pathname !== "/" &&
            window.location.pathname !== "/login"
        ) {
            removeLoggedInUserDetails();
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);
export { invokeApi };

