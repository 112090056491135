import { GET_PLANS_REQUEST, GET_PLANS_SUCCESS } from "./type";

const initialState = {
    getPlans: null,
};

const planReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANS_REQUEST:
            return {
                ...state,
                getPlans: action.payload,
            };
        case GET_PLANS_SUCCESS:
            return {
                ...state,
                getPlans: action.payload,
            };
        default:
            return { ...state };
    }
};
export default planReducer;
