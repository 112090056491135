import {
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,

} from "./type";

const initialState = {
    emailData: null,
};

const landingPageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_EMAIL_REQUEST:
            return {
                ...state,
                emailData: action.payload,
            };
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                emailData: action.payload,
            };
        default:
            return { ...state };
    }
};
export default landingPageReducer;
