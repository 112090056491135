import { GET_PLANS_REQUEST, GET_PLANS_SUCCESS } from "./type"

export const getPlansRequest = () => ({
    type: GET_PLANS_REQUEST,
}
)
export const getPlansSuccess = (data) => ({
    type: GET_PLANS_SUCCESS,
    payload: data
})

