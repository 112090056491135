import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './scss/frontend.scss';
import './i18n/config.js';
import './index.css';  // Assuming this contains your global styles
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import HelmetComponent from './components/Seo/HelmetComponent.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));



root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
